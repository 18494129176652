/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable react/display-name */
import React, { CSSProperties, forwardRef } from "react";
import { pick, omit } from "@styled-system/props";
import { SystemProps, InputWrapper, Flex } from "flicket-ui";
import { components, Props } from "react-select";

import { useId } from "~hooks";

import StyledSelect from "./StyledSelect";
import { CreatableSelect } from "./StyledCreatableSelect";
import { Icon } from "../Icon";

export type SelectOptionType = {
  label: string;
  value: string;
};

type status = "default" | "success" | "warning" | "error";

interface SelectProps extends SystemProps, Props {
  status?: status;
  error?: string;
  label?: string;
  // html props
  // @todo figure out how to combine styled system with React.HTMLProps
  disabled?: boolean;
  onChange?: (val?) => any;
  placeholder?: string;
  type?: "email" | "password";
  creatable?: boolean;
}

const { Option } = components;
const IconOption = (props) => (
  <Option {...props}>
    <Flex>
      {props.data.icon && (
        <Icon icon={props.data.icon} mr={2} mt={"0.07em" as any} />
      )}
      {props.data.label}
    </Flex>
  </Option>
);

export const Select = forwardRef<HTMLSelectElement, SelectProps>(
  (
    {
      error,
      label,
      disabled,
      creatable,
      components,
      styles: stylesProps,
      ...props
    },
    ref
  ) => {
    const id = useId();
    const onChange = (value) =>
      props.onChange(
        props.isMulti ? value?.map(({ value }) => value) || [] : value?.value
      );

    const getValueFromOptions = (value) =>
      // @ts-expect-error this is an operates
      props?.options?.find((o) => o.value == value);

    const hasIcon = props?.options?.some(
      (o) => !!o.icon || !!o.options?.some((o) => !!o.icon)
    );

    const value =
      props?.isMulti && Array.isArray(props?.value)
        ? props?.value?.map(getValueFromOptions)
        : getValueFromOptions(props.value);

    const styles = {
      menuPortal: (baseStyle: CSSProperties): CSSProperties => ({
        ...baseStyle,
        zIndex: 9999,
      }),
    };

    const selectComponents = {
      ...components,
      Option: hasIcon ? IconOption : Option,
    };

    const Select = creatable ? CreatableSelect : StyledSelect;

    return (
      <InputWrapper
        label={label}
        name={props.name}
        error={error}
        {...pick(props)}
      >
        <Select
          ref={ref}
          inputId={`select-${id}`}
          {...omit(props)}
          filterOption={
            typeof props?.filterOption == "function"
              ? (option, rawInput) => props?.filterOption(option, rawInput)
              : undefined
          }
          isInvalid={!!error}
          defaultValue={getValueFromOptions(props.defaultValue)}
          /** we only want a controlled input if we provide the value */
          {...(value && { value: value || [] })}
          onChange={onChange}
          isValid={!error}
          isDisabled={disabled}
          isLoading={props.isLoading}
          components={selectComponents}
          styles={{ ...stylesProps, ...styles }}
        />
      </InputWrapper>
    );
  }
);
