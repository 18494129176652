import React, { ChangeEvent, HTMLProps } from "react";
import { omit, pick } from "@styled-system/props";

import {
  Flex,
  Box,
  SystemProps,
  ErrorMessage,
  UnderlineButton,
} from "flicket-ui";
import styled from "styled-components";
import { ExtendedFile } from "~graphql/sdk";

const Label = styled(Box).attrs({ as: "label" })<HTMLProps<HTMLLabelElement>>`
  position: relative;
  margin-bottom: 4px;
`;

const StyledLabel = styled.span`
  display: block;
  margin-bottom: 8px;

  color: ${(p) => p.theme.colors.N600};
  font-size: ${(p) => p.theme.fontSizes[3]};
  line-height: 100%;
  font-weight: ${(p) => p.theme.fontWeights.extraBold};
  letter-spacing: -0.165px;
`;

const Preview = styled(Flex).attrs({
  as: "span",
  px: 2,
  py: "6/4",
  mr: 1,
  borderRadius: "sm",
  color: "N700",
  fontSize: 2,
})<{ disabled?: boolean; isValid?: boolean }>`
  display: inline-flex;
  align-items: center;

  width: 100%;
  border: 1px solid ${(p) => p.theme.colors[!p.isValid ? "error" : "N200"]};
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.03);

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${(p) => p.theme.colors.N600};

  ${(p) => p.disabled && `background: ${p.theme.colors.N100}`};

  cursor: ${(p) => (p.disabled ? "default" : "pointer")};
  user-select: none;
`;

const Button = styled(Flex).attrs({
  as: "span",
  p: 2,
  borderRadius: "sm",
  color: "N700",
  fontSize: 2,
  fontWeight: "demiBold",
})<{ disabled?: boolean }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  height: 100%;

  background: #fff;

  box-shadow: 0px 4px 29px rgba(0, 0, 0, 0.07),
    0px 1.6711px 12.1155px rgba(0, 0, 0, 0.0503198),
    0px 0.893452px 6.47753px rgba(0, 0, 0, 0.0417275),
    0px 0.500862px 3.63125px rgba(0, 0, 0, 0.035),
    0px 0.266004px 1.92853px rgba(0, 0, 0, 0.0282725),
    0px 0.11069px 0.802504px rgba(0, 0, 0, 0.0196802);

  user-select: none;
  cursor: pointer;

  ${(p) =>
    p.disabled &&
    `
    cursor: default;
    opacity: 0.5
  `};
`;

const Input = styled(Flex).attrs({
  as: "input",
  type: "file",
})`
  visibility: hidden;
  position: absolute;
  left: 0;
  top: 0;
`;

type InputProps = Omit<
  HTMLProps<HTMLInputElement>,
  "size" | "height" | "css" | "color" | "ref" | "width" | "onChange" | "value"
>;

type FileOrFileObject = File | ExtendedFile;

interface FileInputProps extends InputProps, SystemProps {
  onChange?: (value: File) => void;
  value?: FileOrFileObject;
  error?: string;
  imagesOnly?: boolean;
}

export const FileInput = ({
  name,
  label,
  onChange,
  style,
  disabled,
  error,
  value,
  imagesOnly,
  ...props
}: FileInputProps) => {
  const getFileName = (file: FileOrFileObject) => {
    if (!file) return "";
    if (file instanceof File) return file.name;
    return file.originalFileName ?? file.fileName;
  };

  const allowedImageTypes = ".jpg, .jpeg, .png, .gif";

  return (
    <Box {...pick(props)} style={style} position="relative">
      <Label htmlFor={name}>
        {label && <StyledLabel>{label}</StyledLabel>}
        <Flex>
          <Preview disabled={disabled} isValid={!error}>
            {getFileName(value)}
          </Preview>
          <Button disabled={disabled}>Upload</Button>
        </Flex>
        <Input
          disabled={disabled}
          name={name}
          id={name}
          accept={imagesOnly ? allowedImageTypes : null}
          {...omit(props)}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            if (e.target.files?.length) {
              onChange(e.target.files[0]);
            }
            // a hacky way to make the input accept the same file twice
            // https://github.com/ngokevin/react-file-reader-input/issues/11#issuecomment-363484861
            e.target.value = "";
          }}
        />
      </Label>
      <UnderlineButton
        fontSize={2}
        fontWeight={"medium"}
        disabled={disabled}
        onClick={() => !disabled && onChange(null)}
      >
        Remove
      </UnderlineButton>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </Box>
  );
};
