/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React, { FC, forwardRef } from "react";
import { Box, system, SystemProps } from "flicket-ui";
import styled from "styled-components";

import req from "./req";

export const StyledIcon = styled(Box)<SystemProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 1;

  svg {
    vertical-align: middle;
  }

  ${system}
`;

export type IconName =
  | "image-placeholder"
  | "accessibility"
  | "account"
  | "add-cart"
  | "add"
  | "alcohol-free"
  | "arrow-bottom-right"
  | "arrow-dropdown"
  | "arrow-left"
  | "arrow-right"
  | "arrow-top-right"
  | "assignment"
  | "backspace"
  | "badge"
  | "bag"
  | "banner-image-insert"
  | "bars"
  | "bold"
  | "brackets-curly"
  | "broadcast-arrow-line-left-white"
  | "broadcast-error"
  | "broadcast-pencil"
  | "broadcast-warning-white"
  | "bullet-list"
  | "calendar-today"
  | "camera"
  | "campaign"
  | "cards"
  | "caret-bottom"
  | "cart"
  | "cash"
  | "check-circle"
  | "check"
  | "chevron-bottom"
  | "chevron-down"
  | "chevron-left copy"
  | "chevron-left"
  | "chevron-right copy"
  | "chevron-right"
  | "chevron-top"
  | "classes"
  | "clock-clockwise"
  | "close"
  | "code"
  | "copy"
  | "credit_card"
  | "credit-card-in"
  | "credit-card"
  | "creditcard"
  | "cross"
  | "customer"
  | "date-range"
  | "delete"
  | "dismiss"
  | "divider"
  | "double-chevron-left"
  | "double-chevron-right"
  | "dollar"
  | "download"
  | "draft"
  | "drag-handle"
  | "dropdown"
  | "edit-alt"
  | "edit-color"
  | "edit"
  | "email"
  | "email2"
  | "error-circle"
  | "error"
  | "event"
  | "export"
  | "eye"
  | "facebook"
  | "facebook-event"
  | "fb-like"
  | "filter copy"
  | "filter"
  | "flying-mail"
  | "frame"
  | "google copy"
  | "google"
  | "graph"
  | "heading-one"
  | "heading-two"
  | "heading"
  | "help"
  | "home"
  | "image"
  | "info-circle"
  | "info"
  | "instagram"
  | "italic"
  | "lightning"
  | "link"
  | "list"
  | "live-stream"
  | "location"
  | "lock"
  | "logout"
  | "map"
  | "medal"
  | "megaphone"
  | "member"
  | "members"
  | "membership"
  | "menu copy"
  | "menu"
  | "messenger"
  | "minus"
  | "more-vert"
  | "mountain"
  | "my-account"
  | "my-tickets"
  | "negative"
  | "no-data-illustration"
  | "notify"
  | "number-list"
  | "ol"
  | "orders"
  | "overview"
  | "palette"
  | "paper-plane-right"
  | "pencil"
  | "perm_contact_calendar"
  | "play"
  | "plus"
  | "Positive"
  | "price-tag"
  | "print"
  | "priority-high"
  | "puzzle"
  | "radio-checked"
  | "radio"
  | "redeem"
  | "remove-circle"
  | "remove"
  | "reports"
  | "rich-text-button"
  | "robot"
  | "schedule"
  | "search copy"
  | "search"
  | "settings_applications"
  | "settings"
  | "share"
  | "shipping"
  | "shopping-cart"
  | "star"
  | "studio"
  | "support"
  | "teachers"
  | "text-align-left"
  | "text-align-center"
  | "text-align-right"
  | "text-insert"
  | "time-alt"
  | "time"
  | "tiktok"
  | "trash"
  | "trash-new"
  | "trending_up"
  | "twitter"
  | "twitter-email"
  | "ul"
  | "upload"
  | "user-account"
  | "user-square"
  | "user"
  | "users"
  | "video-camera"
  | "warning-circle"
  | "warning-triangle-unfilled"
  | "warning"
  | "workshops"
  | "zoom-in"
  | "credit-card-revenue-white"
  | "ticket-white"
  | "hand-pointing-white"
  | "envelope-simple-open-white"
  | "prohibit"
  | "gear"
  | "pencil-outlined"
  | "download-simple";

export interface IconProps
  extends SystemProps,
    Omit<Omit<React.HTMLAttributes<HTMLDivElement>, "color">, "css"> {
  icon: IconName;
  as?: string;
  href?: string;
  className?: string;
  onClick?: any;
  type?: "button" | "submit";
}

// eslint-disable-next-line react/display-name
export const Icon: FC<IconProps> = forwardRef(
  ({ icon, color, ...props }, ref) => {
    function getAs(): any {
      switch (true) {
        case props.as !== undefined:
          return props.as;
        case props.href !== undefined:
          return "a";
        case props.onClick !== undefined:
          return "button";
        default:
          return "div";
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    const IconSvg = req(`./${icon}.svg`);

    return (
      <StyledIcon ref={ref} aria-hidden as={getAs()} color={color} {...props}>
        {/* eslint-disable-next-line react/jsx-pascal-case */}
        <IconSvg.default />
      </StyledIcon>
    );
  }
);

export default Icon;
